import Swiper from 'swiper'
import { Navigation, Pagination, A11y, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, A11y, Autoplay, Pagination]);

// Industry Slider
let industrySlider = '.industry-slider'
let industrySliderOptions = {
    speed: 400,
    centeredSlides: false,
    slideToClickedSlide: false,
    centeredSlidesBounds: true,
    watchOverflow: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    autoplay: {
        delay: 4000,
        pauseOnMouseEnter: true,
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 'auto',
            spaceBetween: 20,
        },
        // when window width is >= 650px
        650: {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlidesBounds: false,
        },
        // when window width is >= 800px
        800: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
    },
    navigation: {
        nextEl: '.next-1',
        prevEl: '.prev-1',
    },
}

// Logo Slider
let logoSlider = '.logo-slider'
let logoSliderOptions = {
    speed: 400,
    centeredSlides: true,
    slideToClickedSlide: false,
    centeredSlidesBounds: true,
    watchOverflow: true,
    slidesPerView: 'auto',
    spaceBetween: 10,

    breakpoints: {
        // when window width is >= 320px
        320: {
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
        },
        650: {
            slidesPerView: 1,
        },
    },
    navigation: {
        nextEl: '.next-2',
        prevEl: '.prev-2',
    },
}


// Sports Slider
let sportsSlider = '.sports-slider'
let sportsSliderOptions = {
    speed: 400,
    centeredSlides: false,
    slideToClickedSlide: false,
    centeredSlidesBounds: true,
    watchOverflow: true,
    slidesPerView: 'auto',
    spaceBetween: 0,
    autoplay: {
        delay: 4000,
        pauseOnMouseEnter: true,
    },

    breakpoints: {
        // when window width is >= 320px
        320: {
            slidesPerView: 'auto',
            spaceBetween: 20,
        },
        // when window width is >= 650px
        650: {
            slidesPerView: 2,
            spaceBetween: 20,
            centeredSlidesBounds: false,
        },
        // when window width is >= 800px
        800: {
            slidesPerView: 3,
            spaceBetween: 10,
        },
        // when window width is >= 950px
        950: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
    navigation: {
        nextEl: '.next-1',
        prevEl: '.prev-1',
    },
}

// Initialise sliders
new Swiper(industrySlider, industrySliderOptions)
new Swiper(logoSlider, logoSliderOptions)
new Swiper(sportsSlider, sportsSliderOptions)
